$dir__images: '/images';

.icon {
  position: relative;
  display: inline-block;
  vertical-align: top;
  background-repeat: no-repeat;
}
.icon_place {
  background-image: url($dir__images + '/place.svg');
  width: 1.9rem;
  height: 2.5rem;
  margin: -.23rem .5rem 0 0;
}
.icon_phone {
  background-image: url($dir__images + '/phone.svg');
  width: 2.2rem;
  height: 2.2rem;
  margin: 0 .5rem 0 0;
}
.icon_dots_header {
  background-image: url($dir__images + '/dots_header.svg');
  width: .15rem;
  height: 2.96rem;
  margin: 0 .5rem;
}
.icon_eu {
  background-image: url($dir__images + '/icon_eu.svg');
  width: 2.6rem;
  height: 2.6rem;
  margin-top: -.3rem;
}
.icon_de {
  background-image: url($dir__images + '/icon_de.svg');
  width: 2.6rem;
  height: 2.6rem;
  margin-top: -.3rem;
}
.icon_gost {
  background-image: url($dir__images + '/icon_gost.svg');
  width: 2.6rem;
  height: 2.6rem;
  margin-top: -.3rem;
}
.icon_wrnt {
  background-image: url($dir__images + '/icon_wrnt.svg');
  width: 2.6rem;
  height: 2.6rem;
  margin-top: -.3rem;
}
.icon_fs {
  background-image: url($dir__images + '/icon_fs.svg');
  width: 2.6rem;
  height: 2.6rem;
  margin-top: -.3rem;
}
.icon_home {
  background-image: url($dir__images + '/home.svg');
  width: 1.4rem;
  height: 1.1rem;
  margin-top: .2rem;
}
.icon_thumbs-up {
  background-image: url($dir__images + '/thumbs-up.svg');
  width: 7rem;
  height: 7.3rem;
}
.icon_step_1 {
  background-image: url($dir__images + '/step_1.svg');
  width: 7.2rem;
  height: 7.2rem;
}
.icon_step_2 {
  background-image: url($dir__images + '/step_2.svg');
  width: 7.2rem;
  height: 7.2rem;
}
.icon_step_3 {
  background-image: url($dir__images + '/step_3.svg');
  width: 7.2rem;
  height: 7.2rem;
}
.icon_step_4 {
  background-image: url($dir__images + '/step_4.svg');
  width: 7.2rem;
  height: 7.2rem;
}
.icon_step_5 {
  background-image: url($dir__images + '/step_5.svg');
  width: 7.2rem;
  height: 7.2rem;
}
.icon_step_6 {
  background-image: url($dir__images + '/step_6.svg');
  width: 7.2rem;
  height: 7.2rem;
}
