@import "../../bower_components/bootstrap/scss/bootstrap";
@import "icons";
@import "calculate-line-height";

$__color_white: #ffffff;
$__color_black: #000000;
$__color_grey: #e7e7e7;
$__color_navy: #05408b;
$__color_blue: #069ad8;
$__color_orange: #fbbf05;

$__border-radius: .3;
$dir__images: '/images';

/* ------------------------------------------------ */

html {
  font-size: 62.5%;
}
body {
  font-family: 'PT Sans Caption', sans-serif;
  background-color: $__color_white;
  color: $__color_black;
  font-size: 1.3rem;
}


/* Main */

a {
  color: $__color_blue;

  &:hover, &:focus {
    color: $__color_orange;
  }
}

.__bg_white { background-color: $__color_white; }
.__bg_black { background-color: $__color_black; }
.__bg_grey { background-color: $__color_grey; }
.__bg_navy { background-color: $__color_navy; }
.__bg_blue { background-color: $__color_blue; }
.__bg_orange { background-color: $__color_orange; }

.__text_white { color: $__color_white; }
.__text_black { color: $__color_black; }
.__text_grey { color: $__color_grey; }
.__text_navy { color: $__color_navy; }
.__text_blue { color: $__color_blue; }
.__text_orange { color: $__color_orange; }

.__btn {
  background-color: $__color_orange;
  color: $__color_white;
  text-transform: uppercase;
  font-weight: 700;
  @include button-variant($__color_white, $__color_orange, $__color_orange);
  padding-left: $spacer-x * 3;
  padding-right: $spacer-x * 3;
}
.__btn_thin {
  padding-top: .3rem;
  padding-bottom: .3rem;
}
.__font-weight-bold {
  font-weight: 700 !important;
}
.__rail {

  &::before {
    content: '';
    display: block;
    height: 100%;
    position: absolute;
    top: 0;
    width: 18rem;
    left: auto;
    right: 100%;
  }

  &.__rail_blue {
    background-color: $__color_blue;

    &::before {
      background-color: $__color_blue;
    }
  }
  &.__rail_orange {
    background-color: $__color_orange;

    &::before {
      background-color: $__color_orange;
    }
  }
  &.__rail_quote {
    background: $__color_orange url($dir__images + '/qutel.png') no-repeat 0 0;

    /*&::after {
      content: '«';
      position: absolute;
      left: 0;
      top: 0;
      font-size: 40rem;
      line-height: .3;
      font-weight: 700;
      color: rgba(255,255,255,.35);
      z-index: 1;
    }*/
  }
}
.__row-eq-height {

  @include media-breakpoint-up(lg) {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
  }
}
.__py-10 {
  padding-top: 10rem !important;
  padding-bottom: 10rem !important;
}
.__header_review {
  position: relative;
  z-index: 2;
  padding-left: $spacer-x * 3;

  @include media-breakpoint-up(lg) {
    padding-left: 10rem;
  }
}


/* Header */

.__logo {
  margin-right: $spacer-x * 2;

  @include media-breakpoint-down(xs) {
    margin-right: 0;
  }
}
.__address_1 {
  font-size: 1.4rem;
  position: relative;

  &::before {
    content: '';
    display: block;
    position: absolute;
    left: -3rem;
    top: -.3rem;
    background-image: url($dir__images + '/place.svg');
    width: 1.9rem;
    height: 2.4rem;
  }
  &::after {
    content: '';
    display: block;
    position: absolute;
    right: -1.5rem;
    top: .2rem;
    background-image: url($dir__images + '/dots_header.svg');
    width: .2rem;
    height: 3rem;
  }
}
.__address_2 {
  position: relative;

  &::before {
    content: '';
    display: block;
    position: absolute;
    left: -3.5rem;
    top: -.3rem;
    background-image: url($dir__images + '/phone.svg');
    width: 2.19rem;
    height: 2.19rem;
  }
}
.__menu_top {

  li {
    padding-left: $spacer-x * 3;

    @include media-breakpoint-down(sm) {
      padding-left: $spacer-x * 1;
      padding-right: $spacer-x * 1;
      margin-right: 0;
    }
  }

}
.__menu_main {
  position: relative;
  list-style: none;
  padding: 0;
  margin-bottom: 0;
  bottom: -2.5rem;
  margin-top: -2.5rem;
  background-color: $__color_blue;
  text-transform: uppercase;
  border-radius: $__border-radius + rem;
  font-size: 0;

  text-align: justify;
  -moz-text-align-last: justify;
  text-align-last: justify;

  a {
    display: block;
    color: $__color_white;
    padding: .5rem 1.5rem;
    text-decoration: none;
    white-space: nowrap;

    &:hover {
      background-color: $__color_orange;
    }
  }
  &>li {
    position: relative;
    display: inline-block;
    text-align: left;
    vertical-align: top;
    font-size: 1.3rem;
    font-weight: 700;

    &::before {
      content: url($dir__images + '/dots_menu.svg');
      position: absolute;
      display: block;
      width: .15rem;
      height: 1.84rem;
      top: 50%;
      left: -.4rem;
      margin-top: -.92rem;
    }
    @include media-breakpoint-down(lg) {
      &::before {
        display: none;
      }
    }

    &>a {
      padding: 1.5rem;
    }

    div {
      display: none;
      position: absolute;
      padding-top: 1.5rem;
      z-index: 1;
    }
    ul {
      position: relative;
      background-color: $__color_blue;
      list-style: none;
      padding: 1rem 0;
      margin: 0;
      text-transform: none;
      font-weight: 400;
      border-radius: $__border-radius + rem;

      text-align: left;
      -moz-text-align-last: left;
      text-align-last: left;

      &:after {
        bottom: 100%;
        left: 5rem;
        border: solid transparent;
        content: " ";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
        border-bottom-color: $__color_blue;
        border-width: 1.5rem;
        margin-left: -1.5rem;
      }
    }
    &:hover {
      div {
        display: block;
      }
    }
    &:first-child {
      &::before {
        display: none;
      }
    }
    &:last-child {
      div {
        right: 0;
      }
      ul {
        &:after {
          left: auto;
          right: 5rem;
        }
      }
    }
  }

}
.__touch {
  .__menu_main {

    text-align: center;
    -moz-text-align-last: center;
    text-align-last: center;

    &>li {
      display: block;

      &::before {
        display: none;
      }

      div {
        display: none;
        position: relative;
        padding-top: 0;
        z-index: auto;
      }
      /*&.dropdown {
        &>a {
          margin-right: 4rem;

          &::after {
            content: '\25BC';
            padding: 1.5rem;
            position: absolute;
            top: 0;
            right: 0;
          }
        }
        div {
          display: none;
          position: relative;
          padding-top: 0;
          z-index: auto;
        }

        &.open {
          background-color: $__color_navy;

          &>a {
            &::after {
              content: '\25B2';
            }
          }
          div {
            display: block;
          }
        }
      }*/
    }
    ul {
      background-color: $__color_orange;

      &:after {
        display: none;
      }
    }
  }
}

.__arrow_up {
  content: '\25B2';
  color: $__color_white;
  padding: 1.5rem;
  position: absolute;
  right: 0;
  top: 0;
  display: block;
  cursor: pointer;
}
.__arrow_down {
  content: '\25BC';
  color: $__color_white;
  padding: 1.5rem;
  position: absolute;
  right: 0;
  top: 0;
  display: block;
  cursor: pointer;
}


/* Elements */

.__breadcrumbs {
  font-size: 0;
  font-weight: 700;

  li {
    font-size: 1.1rem;

    &::before {
      content: '> ';
      margin: 0 .5rem;
    }
    &:first-child {

      &::before {
        display: none;
      }
    }
  }
}
.__text-column-2 {

  @include media-breakpoint-up(md) {
    column-count: 2;
    column-gap: $grid-gutter-width-base;
  }
}
.__text_justify {
  text-align: justify;
  -moz-text-align-last: justify;
  text-align-last: justify;

  @include media-breakpoint-down(md) {
    text-align: center;
    -moz-text-align-last: center;
    text-align-last: center;
  }
  li {
    text-align: center;
    -moz-text-align-last: center;
    text-align-last: center;
  }
}


/* Sections */

.__section_main {
  background: transparent url($dir__images + '/bg__main.jpg') repeat-y top center;
  min-height: 40rem;
  padding-top: 7rem;
}
.__section_consult-1 {
  background: transparent url($dir__images + '/bg__consult_1.jpg') repeat-y top center;
  min-height: 13.8rem;
}
.__section_consult-2 {
  background: transparent url($dir__images + '/bg__consult_2.jpg') repeat-y top center;
  min-height: 13.8rem;
}
.__section_consult-3 {
  background: transparent url($dir__images + '/bg__consult_3.jpg') repeat-y top center;
  min-height: 13.8rem;
}
.__section_consult-4 {
  background: transparent url($dir__images + '/bg__consult_4.jpg') repeat-y center center;
  min-height: 13.8rem;
}
.__section_benf {
  background: transparent url($dir__images + '/bg__benf.jpg') repeat-y top center;
  min-height: 78.3rem;
}


/* Custom */

.__fieldset {
  border: .1rem solid $__color_blue;
  border-radius: $__border-radius + rem;
  padding: .5rem;
  text-align: center;

  &.__fieldset_white {
    border-color: $__color_white;
  }

  legend {
    width: auto;
  }
  .btn {
    position: relative;
    bottom: -1.75rem;
  }
}
.__benf {
  position: relative;
  border: .1rem solid $__color_white;
  border-radius: $__border-radius + rem;
  padding: 2rem 2rem 2rem 5.8rem;
  margin: 0 3rem 3rem;
  font-size: 1.3rem;

  @include media-breakpoint-up(md) {
    min-height: 26rem;
  }
  @include media-breakpoint-down(lg) {
    margin-left: 0;
    margin-right: 0;
    padding: 2rem;
  }
  &::before {
    content: '';
    display: block;
    position: absolute;
    width: 7.7rem;
    height: 7.7rem;
    left: -3.85rem;
    top: 1.5rem;

    @include media-breakpoint-down(lg) {
      display: none;
    }
  }


  &.__benf_1 {

    &::before {
      background-image: url($dir__images + '/benf_1.svg');
    }
  }
  &.__benf_2 {

    &::before {
      background-image: url($dir__images + '/benf_2.svg');
    }
  }
  &.__benf_3 {

    &::before {
      background-image: url($dir__images + '/benf_3.svg');
    }
  }
  &.__benf_4 {

    &::before {
      background-image: url($dir__images + '/benf_4.svg');
    }
  }
  &.__benf_5 {

    &::before {
      background-image: url($dir__images + '/benf_5.svg');
    }
  }
  &.__benf_6 {

    &::before {
      background-image: url($dir__images + '/benf_6.svg');
    }
  }
}
.__news-list {
  font-size: 0;
  padding-left: 0;

  li {
    display: inline-block;
    vertical-align: top;

    float: none;
    font-size: 1.3rem;
    padding-bottom: ($spacer-y * 1.5) !important;
  }
}
.__modal {
  background-color: rgba(0,0,0,.6);

  .modal-dialog {
    margin-top: 15%;
  }
}
.__table_service {
  tr {
    td, th {
      border: 0;
      padding-left: ($spacer-y * 2) !important;
      padding-right: ($spacer-y * 2) !important;

      &:last-child {
        font-weight: 700;
        text-align: center;
      }
    }
    td {

      &:last-child {
        border-left: ($spacer-y * 2) solid $__color_white;
      }
    }
  }
  tbody tr:nth-of-type(2n+1) {
    background-color: $__color_grey;
  }
}
.__table_price {
  td {
    border: 0;
    padding-top: ($spacer-y * 2) !important;
    padding-bottom: ($spacer-y * 2) !important;
  }

  thead {
    th {
      border: 0;
      text-transform: uppercase;
      font-weight: 700;
      text-align: center;
      vertical-align: middle;
    }
  }
}
.__table_savings {
  td {
    border: 0;
  }
  thead {
    th {
      border: 0;
    }
  }
  tfoot {
    td {
      border-top: .1rem solid $__color_blue;
    }
  }
}
.__nav-tabs {
  border-bottom: .2rem solid $__color_orange;

  .nav-link {
    background-color: $__color_grey;
    border-color: $__color_grey $__color_grey transparent;
    color: $__color_navy;

    &.active {
      background-color: $__color_orange;
      border-color: $__color_orange $__color_orange transparent;
      color: $__color_navy;

      &:focus,
      &:hover {
        background-color: $__color_orange;
        border-color: $__color_orange $__color_orange transparent;
        color: $__color_navy;
      }
    }
  }
  .nav-item {
    margin-bottom: 0;

    &.open {
      .nav-link {
        &:focus,
        &:hover {
          background-color: $__color_orange;
          border-color: $__color_orange $__color_orange transparent;
          color: $__color_navy;
        }
      }
    }
  }
}


/* Fixes */

.form-control-lg,
.input-group-lg > .form-control,
.input-group-lg > .input-group-addon,
.input-group-lg > .input-group-btn > .btn {
  padding: .5rem .75rem;
}
.custom-select {
  cursor: pointer;
}
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type="number"] {
  -moz-appearance: textfield;
}

